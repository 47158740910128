import gsap from "gsap";

export default (el) => ({
	container: el,
	content: el.querySelector(".c-split__content, .c-hero-split__content"),
	media: el.querySelector(".c-split__media, .c-hero-split__media"),

	fadeIn() {
		const mm = gsap.matchMedia();

		mm.add({
			isDesktop: "(min-width: 992px)",
			reduceMotion: "(prefers-reduced-motion: reduce)",
		}, (ctx) => {
			const { reduceMotion } = ctx.conditions;

			this.children = [...this.content.children];

			gsap.set(this.children, {
				x: reduceMotion ? 0 : 50,
				autoAlpha: reduceMotion ? 1 : 0,
			});

			this.anim = gsap.timeline({
				scrollTrigger: {
					trigger: this.container,
					start: "top bottom-=100px",
					onEnter: () => this.media ? this.media.classList.add("is-active") : null,
				},
			});

			this.anim.to(
				this.children,
				{
					x: 0,
					autoAlpha: 1,
					ease: "power4.out",
					overwrite: "auto",
					duration: reduceMotion ? 0 : 1,
					stagger: 0.08,
				},
				0.2
			);
		})
	},
})
