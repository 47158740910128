import { Element } from "@/scripts/extends";

export default class Collapse extends Element {
	mounted() {
		this.header = this.el.querySelector('.c-collapse__header');
		this.container = this.el.querySelector('.c-collapse__container');

		this.animation = null;
		this.isClosing = false;
		this.isExpanding = false;
	}

	events() {
		this.header.addEventListener('click', (e) => this.onClick(e));
	}

	onClick(e) {
		e.preventDefault();

		this.el.style.overflow = 'hidden';

		if (this.isClosing || !this.el.open) {
			this.open();
		} else if (this.isExpanding || this.el.open) {
			this.close();
		}
	}

	close() {
		this.el.classList.remove("is-open");
		this.header.setAttribute("aria-expanded", "false");

		this.isClosing = true;

		const startHeight = `${this.el.offsetHeight}px`;
		const endHeight = `${this.header.offsetHeight}px`;

		if (this.animation) {
			this.animation.cancel();
		}

		this.animation = this.el.animate({
			height: [startHeight, endHeight]
		},{
			duration: 200,
			easing: 'ease-in-out'
		});

		this.animation.onfinish = () => this.onAnimationFinish(false);
		this.animation.oncancel = () => this.isClosing = false;
	}

	open() {
		this.el.classList.add("is-open");
		this.header.setAttribute("aria-expanded", "true");

		this.el.style.height = `${this.el.offsetHeight}px`;
		this.el.open = true;

		window.requestAnimationFrame(() => this.expand());
	}

	expand() {
		this.isExpanding = true;

		const startHeight = `${this.el.offsetHeight}px`;
		const endHeight = `${this.header.offsetHeight + this.container.offsetHeight}px`;

		if (this.animation) {
			this.animation.cancel();
		}

		this.animation = this.el.animate({
			height: [startHeight, endHeight]
		},{
			duration: 400,
			easing: 'ease-out'
		});

		this.animation.onfinish = () => this.onAnimationFinish(true);
		this.animation.oncancel = () => this.isExpanding = false;
	}

	onAnimationFinish(open) {
		this.el.open = open;
		this.animation = null;
		this.isClosing = false;
		this.isExpanding = false;

		this.el.style.height = this.el.style.overflow = '';
	}

	destroy() {
		this.header.removeEventListener('click', (e) => this.onClick(e));
	}
}
