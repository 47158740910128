import { Base } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

class Mouse extends Base {
	init() {
		this.pageX = null;
		this.pageY = null;
		this.clientX = null;
		this.clientY = null;
	}

	events() {
		window.addEventListener("mousemove", (e) => this.onMouseMove(e));
		window.addEventListener("mousedown", this.onMouseDown);
		window.addEventListener("mouseup", this.onMouseUp);
		document.addEventListener("mouseenter", (e) => this.setInitialValues(e));
		window.addEventListener("scrollend", (e) => this.onMouseScroll(e));
	}

	onMouseMove(e) {
		EventEmitter.emit(EVENTS.MOUSE_MOUSE_MOVE, e);

		this.pageX = e.pageX;
		this.pageY = e.pageY;
		this.clientX = e.clientX;
		this.clientY = e.clientY;
	}

	onMouseScroll(e) {
		this.pageX = e.pageX;
		this.pageY = e.pageY;
	}

	setInitialValues(e) {
		if(!this.mouseX && !this.mouseY) {
			this.pageX = e.pageX;
			this.pageY = e.pageY;
			this.clientX = e.clientX;
			this.clientY = e.clientY;
		}
	}

	onMouseDown(e) {
		EventEmitter.emit(EVENTS.MOUSE_MOUSE_DOWN, e);
	}

	onMouseUp(e) {
		EventEmitter.emit(EVENTS.MOUSE_MOUSE_UP, e);
	}

	listenOnMouseMove(callback) {
		EventEmitter.on(EVENTS.MOUSE_MOUSE_MOVE, callback);
	}

	listenOnMouseDown(callback) {
		EventEmitter.on(EVENTS.MOUSE_MOUSE_DOWN, callback);
	}

	listenOnMouseUp(callback) {
		EventEmitter.on(EVENTS.MOUSE_MOUSE_UP, callback);
	}

	destroy() {
		window.removeEventListener("mousedown", this.onMouseDown);
		window.removeEventListener("mouseup", this.onMouseUp);
		window.removeEventListener("mousemove", (e) => this.onMouseMove(e));
		document.removeEventListener("mouseenter", (e) => this.setInitialValues(e));
		window.addEventListener("scrollend", (e) => this.onMouseScroll(e));
	}
}

const mouse = new Mouse();

export default mouse;
