import { Element } from "@/scripts/extends";
import { Window } from "@/scripts/singletons";
import keyboard from "../singletons/Keyboard";

export default class Header extends Element {
	mounted() {
		this.isOpen = false;
		this.body = document.body;
		this.navActiveClass = "nav-is-open";

		this.initKeyboard();
	}

	events() {
		Window.listenOnResize(() => this.handleResize());
	}

	initKeyboard() {
		keyboard.listenOnKeyDown((e) => this.onKeydown(e));
	}

	onKeydown(e) {
		this.handleEsc(e);
	}

	handleEsc(e) {
		const isEsc = 27;

		if (e.keyCode === isEsc) {
			this.body.classList.remove(this.navActiveClass);
		}
	}

	handleResize() {
		if (window.innerWidth >= 960 && this.body.classList.contains(this.navActiveClass)) {
			this.body.classList.remove(this.navActiveClass);
		}
	}
}
