import { Element } from "@/scripts/extends";

export default class Hamburger extends Element {
	mounted() {
		this.activeBodyClass = "nav-is-open";
		this.body = document.body;
		this.navIsOpen = this.body.classList.contains(this.activeBodyClass);

		this.labelEl = this.el.querySelector(".c-hamburger__label");
		this.openLabel = this.el.dataset.labelOpen;
		this.closeLabel = this.el.dataset.labelClose;
	}

	events() {
		this.el.addEventListener("click", (e) => this.handleClick(e));
	}

	handleClick(e) {
		if (e) {
			this.toggle();
		}
	}

	toggle() {
		if (this.navIsOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	close() {
		this.navIsOpen = false;

		this.body.classList.remove(this.activeBodyClass);
		this.el.setAttribute("aria-label", this.openLabel);
		this.el.setAttribute("aria-expanded", "true");
	}

	open() {
		this.navIsOpen = true;

		this.body.classList.add(this.activeBodyClass);
		this.el.setAttribute("aria-label", this.closeLabel);
		this.el.setAttribute("aria-expanded", "false");
	}
}
