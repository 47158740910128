import { Element } from "@/scripts/extends";
import keyboard from "../singletons/Keyboard";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Overlay extends Element {
	mounted() {
		this.isShown = false;
		this.body = document.body;
		this.background = this.el.querySelector(".c-overlay__background");
		this.name = this.el.getAttribute("data-name");
		this.initKeyboard();
		this.activeClass = "is-active";
		this.activeBodyClass = "overlay-is-open";
	}

	events() {
		EventEmitter.on(`${EVENTS.OVERLAY_TRIGGER_SHOW_OVERLAY}-${this.name}`, () => this.setShowState());
		EventEmitter.on(`${EVENTS.OVERLAY_MODAL_CLOSE_CLICK}-${this.name}`, () => this.setHiddenState());
		this.background.addEventListener("click", () => this.setHiddenState());
	}

	destroy() {
		if (this.background) {
			this.background.removeEventListener("click", () => this.setHiddenState());
		}
	}

	initKeyboard() {
		keyboard.listenOnKeyDown((e) => this.onKeydown(e));
	}

	onKeydown(e) {
		if (!this.isShown) {
			return;
		}

		this.handleEsc(e);
	}

	handleEsc(e) {
		const isEsc = 27;

		if (e.keyCode === isEsc) {
			this.setHiddenState();
			e.preventDefault();
		}
	}

	setShowState() {
		this.el.classList.add(this.activeClass);
		this.isShown = true;
		EventEmitter.emit(EVENTS.OVERLAY_SHOW);
		this.body.classList.add(this.activeBodyClass);
	}

	setHiddenState() {
		this.el.classList.remove(this.activeClass);
		this.isShown = false;
		EventEmitter.emit(EVENTS.OVERLAY_HIDE);
		this.body.classList.remove(this.activeBodyClass);
	}
}
