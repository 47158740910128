import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class OverlayTrigger extends Element {
	mounted() {
		this.setOverlay();
	}

	events() {
		this.el.addEventListener("click", (event) => this.onClick(event))
	}

	setOverlay() {
		this.overlay = this.el.getAttribute("data-overlay");
	}

	onClick(event) {
		event.preventDefault();

		this.showOverlay();
	}

	showOverlay() {
		EventEmitter.emit(`${EVENTS.OVERLAY_TRIGGER_SHOW_OVERLAY}-${this.overlay}`);
	}
}
