import { Element } from "@/scripts/extends";

export default class Logos extends Element {
	mounted() {
		this.rows = this.el.querySelectorAll(".c-logos__row");

		this.fillRows();
		// this.shuffle();
	}

	fillRows() {
		this.rows.forEach(row => {
			// Calculate the total width of all items
			const itemWidth = row.children[0].clientWidth;
			const childrenCount = row.children.length;
			let totalWidth = itemWidth * childrenCount;

			// Calculate how many times we need to duplicate the items
			const timesToDuplicate = Math.ceil(window.innerWidth / totalWidth);

			// Duplicate children based on the calculated times + 1 to be sure
			for (let i = 0; i < timesToDuplicate + 1; i++) {
				for (let j = 0; j < childrenCount; j++) {
					const clonedChild = row.children[j].cloneNode(true);
					row.appendChild(clonedChild);
				}
			}
		});
	}

	shuffle() {
		this.rows.forEach(row => {
			// Shufle array
			const items = [...row.children];
			const shuffledItems = this.shuffleArray(items);

			// Update DOM
			row.innerHTML = '';
			shuffledItems.forEach(item => row.appendChild(item));
		});
	}

	shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}
}
