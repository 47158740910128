import { Element } from "@/scripts/extends";

export default class Embed extends Element {
	mounted({ pauseCb, endedCb }) {
		this.pauseCb = pauseCb;
		this.endedCb = endedCb;
	}

	events() {
		this.el.addEventListener("pause", (e) => this.handlePause(e));
		this.el.addEventListener("ended", () => this.handleEnded());
	}

	play() {
		this.el.play();
	}

	stop() {
		this.el.pause();
	}

	handlePause(e) {
		if (e) {
			this.pauseCb();
		}
	}

	handleEnded(e) {
		if (e) {
			this.endedCb();
		}
	}
}
