/**
 * In the /scripts/singletons/DomManager file, when using the setComponents
 * method, all components placed in export default are looped and initialized
 * automatically.
 */
import Button from "@/scripts/components/Button";
import Flyout from "@/scripts/components/Flyout";
import Case from "@/scripts/components/Case";
import CursorHover from "@/scripts/components/CursorHover";
import Hamburger from "@/scripts/components/Hamburger";
import Header from "@/scripts/components/Header";
import Slider from "@/scripts/components/Slider";
import Video from "@/scripts/components/Video";
import Tooltip from "@/scripts/components/Tooltip";
import DataLayer from "@/scripts/components/DataLayer";
import Collapse from "@/scripts/components/Collapse";
import Logos from "@/scripts/components/Logos";
import InteractiveTags from "@/scripts/components/InteractiveTags";
import List from "@/scripts/components/List";
import LanguageToggle from "@/scripts/components/LanguageToggle";
import SubNav from "@/scripts/components/SubNav";
import Overlay from "@/scripts/components/Overlay";
import Split from "@/scripts/components/Split";
import TileAnimation from "@/scripts/components/TileAnimation";

/**
 * If you want to pass separate parameters or want to initialize a component
 * manually, you have to:
 * - Export it by itself =>
 *   export { default as Button } from "@/scripts/components/Button";
 * - Import it in the main.js file =>
 *   import { Button } from "@/scripts/components";
 * - Use the DomManager to register it:
 *   DomManager.registerComponent(".js-button", Button);
 */
// export { default as Button } from "@/scripts/components/Button";

export default {
	Button,
	Flyout,
	Case,
	CursorHover,
	Hamburger,
	Header,
	Slider,
	Video,
	Tooltip,
	DataLayer,
	Collapse,
	Logos,
	InteractiveTags,
	List,
	LanguageToggle,
	SubNav,
	Overlay,
	Split,
	TileAnimation,
};
