import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Collapse extends Element {
	mounted() {
		this.button = this.el.querySelector('.c-list__trigger');
		this.name = this.el.getAttribute('data-name');
	}

	events() {
		if (this.button) {
			this.button.addEventListener('click', (e) => this.onClick(e));
		}
	}

	onClick(e) {
		e.preventDefault();

		EventEmitter.emit(`${EVENTS.OVERLAY_TRIGGER_SHOW_OVERLAY}-${this.name}`);
	}
}
