import { Element } from "@/scripts/extends";

export default class DataLayer extends Element {
	mounted() {
		this.handleClick = this.handleClick.bind(this);
	}

	events() {
		this.el.addEventListener("click", this.handleClick);
	}

	handleClick() {
		if (window.dataLayer) {
			// Get attributes from element
			let dataLayerProps = JSON.parse(this.el.getAttribute("data-layer"));

			// You can declare defaults here
			let dataLayerArr = {};

			// Override defaults
			const mergedConfig = {
				...dataLayerArr,
				...dataLayerProps
			};

			if (mergedConfig) {
				// TODO: Remove on Production
				console.log(mergedConfig);

				// TODO: uncomment to test dataLayer
				// window.dataLayer.push(mergedConfig);
			}
		}
	}

	destroy() {
		this.el.removeEventListener("click", this.handleClick);
	}
}
