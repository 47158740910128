import { Element } from "@/scripts/extends";
import { EVENTS, BREAKPOINTS } from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";

class Body extends Element {
	mounted() {}

	events() {
		this.el.addEventListener("click", (e) => this.onClickBody(e));

		this.resizeObserver = new ResizeObserver((entries) => {
			const body = entries[0];

			const storedActiveBreakpoint = this.activeBreakpoint;

			this.activeBreakpoint = this.calculateActiveBreakpoint(body.contentRect.width)

			EventEmitter.emit(EVENTS.SCREEN_SIZE_CHANGE, this.activeBreakpoint);

			if (storedActiveBreakpoint === this.activeBreakpoint) {
				return;
			}

			EventEmitter.emit(EVENTS.BREAKPOINT_CHANGE, this.activeBreakpoint);
		});

		this.resizeObserver.observe(this.el);
	}

	onClickBody(e) {
		EventEmitter.emit(EVENTS.CURRENT_CLICK_TARGET, e.target);
	}

	calculateActiveBreakpoint(width) {
		if (width <= BREAKPOINTS.VP_5) {
			return BREAKPOINTS.VP_3;
		} else if (width > BREAKPOINTS.VP_5 && width <= BREAKPOINTS.VP_6) {
			return BREAKPOINTS.VP_5;
		} else if (width > BREAKPOINTS.VP_6 && width <= BREAKPOINTS.VP_7) {
			return BREAKPOINTS.VP_6;
		} else if (width > BREAKPOINTS.VP_7 && width <= BREAKPOINTS.VP_9) {
			return BREAKPOINTS.VP_7;
		} else if (width > BREAKPOINTS.VP_9 && width <= BREAKPOINTS.VP_12) {
			return BREAKPOINTS.VP_9;
		} else if (width > BREAKPOINTS.VP_12 && width <= BREAKPOINTS.VP_15) {
			return BREAKPOINTS.VP_12;
		} else if (width > BREAKPOINTS.VP_15 && width <= BREAKPOINTS.VP_22) {
			return BREAKPOINTS.VP_15;
		} else if (width < BREAKPOINTS.VP_22) {
			return BREAKPOINTS.VP_22;
		}
	}
}

export default Body;
