/**
 * In the /scripts/singletons/DomManager file, when using the setModules method,
 * all modules placed in export default are looped and initialized
 * automatically.
 */
import LoadMore from "@/scripts/modules/LoadMore";
import OverlayClose from "@/scripts/modules/OverlayClose";
import OverlayTrigger from "@/scripts/modules/OverlayTrigger";

/**
 * If you want to pass separate parameters or want to initialize a module
 * manually, you have to:
 * - Export it by itself =>
 *   export { default as LoadMore } from "@/scripts/modules/LoadMore";
 * - Import it in the main.js file =>
 *   import { LoadMore } from "@/scripts/modules";
 * - Use the DomManager to register it:
 *   DomManager.registerModule(".js-load-more", LoadMore);
 */
// export { default as LoadMore } from "@/scripts/modules/LoadMore";

export default {
	LoadMore,
	OverlayClose,
	OverlayTrigger,
};
