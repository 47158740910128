import gsap from "gsap";

export default (el) => ({
	fadeIn() {
		const mm = gsap.matchMedia();

		mm.add({
			isDesktop: "(min-width: 992px)",
			reduceMotion: "(prefers-reduced-motion: reduce)",
		}, (ctx) => {
			const { reduceMotion } = ctx.conditions;

			this.children = [...el.children];

			gsap.fromTo(
				this.children,
				{
					autoAlpha: reduceMotion ? 1 : 0,
				},
				{
					scrollTrigger: {
						trigger: el,
						start: "top bottom-=350px",
					},
					autoAlpha: 1,
					ease: "power2.out",
					overwrite: "auto",
					duration: reduceMotion ? 0 : 1.4,
					stagger: 0.1,
				},
				0.2
			);
		})
	},
})
