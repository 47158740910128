import tippy from "tippy.js";

import { Element } from "@/scripts/extends";

export default class Tooltip extends Element {
	mounted() {
		this.content = this.el.querySelector(".c-tooltip__content");
		this.button = this.el.querySelector(".c-tooltip__button");

		tippy(this.button, {
			theme: "custom",
			content: this.content.innerHTML,
			allowHTML: true,
			placement: "auto",
			interactive: true,
		});
	}
}


