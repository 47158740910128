export default {
	KEYBOARD_KEY_DOWN: "keyboard-key-down",
	KEYBOARD_KEY_UP: "keyboard-key-up",
	KEYBOARD_KEY_PRESS: "keyboard-key-press",
	MOUSE_MOUSE_MOVE: "mouse-mouse-move",

	OVERLAY_TRIGGER_SHOW_OVERLAY: "overlay-trigger-show-overlay",
	OVERLAY_MODAL_CLOSE_CLICK: "overlay-modal-close-click",
	OVERLAY_SHOW: "overlay-show",
	OVERLAY_HIDE: "overlay-hide",

	BREAKPOINT_CHANGE: "breakpoint-change",
	SCREEN_SIZE_CHANGE: "screen-size-change",

	SUB_NAV_TOGGLE: "sub-nav-toggle",

	CURRENT_CLICK_TARGET: null,
};
