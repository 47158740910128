import { Element } from "@/scripts/extends";

export default class InteractiveTags extends Element {
	mounted() {
		this.rows = this.el.querySelectorAll(".c-interactive-tags__row");

		this.shuffle();
	}

	shuffle() {
		this.rows.forEach(row => {
			// Shufle array
			const items = Array.from(row.children);
			const shuffledItems = this.shuffleArray(items);

			// Update DOM
			row.innerHTML = '';
			shuffledItems.forEach(item => row.appendChild(item));
		});
	}

	shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}
}
