import { DomManager, Store } from "@/scripts/core";
import { Body } from "@/scripts/singletons";
import components from "@/scripts/components";
import modules from "@/scripts/modules";
import AOS from "aos";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

/* Lazysizes plugin to lazy load images */
// DOCS: https://github.com/aFarkas/lazysizes
import "lazysizes";

/* Load in css libraries */
// DOCS: https://swiperjs.com/
import "swiper/css";

// DOCS: https://github.com/michalsnik/aos
import "aos/dist/aos.css";

// DOCS: https://atomiks.github.io/tippyjs/
// import "tippy.js/dist/tippy.css";

/**
 * Set up the initial values and keys for the store. This should be done first
 * thing before mounting and creating the components.
 */
Store.setState({});

// EXTEND:Sprig + Vanilla JS
function init() {
	DomManager.setComponents(components);
	DomManager.setModules(modules);
}

DomManager.define({
	/**
	 * Triggers first and immediately, does not wait for a state. Only
	 * initialize DOM based classes that do not require calculations with
	 * dimensions, because the dimensions of certain elements might not be set
	 * immediately.
	 */
	created() {
		const isReduced =
			window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
			window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

		if (isReduced) {
			// DON'T use an animation here!
			AOS.init({
				disable: true,
			});
		} else {
			// DO use an animation here!
			AOS.init({
				once: true,
			});
		}
	},

	/**
	 * Triggers on 'interactive' state, if 'interactive' never fires, it is
	 * triggered on 'complete' state. Dimensions are known and set
	 */
	mounted() {
		// disable gsap warnings
		gsap.config({ nullTargetWarn: false });

		// Register plugins
		gsap.registerPlugin(ScrollTrigger);

		ScrollTrigger.defaults({
			// Set markers to false when deploying!
			markers: false,
		});

		init();
	},

	// Triggers last and on 'complete' state
	completed() {
		new Body(document.body);
	},
});

// EXTEND:Sprig + Vanilla JS
document.addEventListener("sprig-refresh", () => {
	DomManager.destroy();
	init();
});
