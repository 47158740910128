import { Element } from "@/scripts/extends";
import { Mouse } from "@/scripts/singletons";
import { Window } from "@/scripts/singletons";
import { EVENTS } from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";

export default class CursorHover extends Element {
	mounted() {
		this.cursor = this.el.querySelector(".js-cursor");

		this.left = this.getCoordinates("left");
		this.top = this.getCoordinates("top");
	}

	events() {
		this.el.addEventListener("mouseenter", () => this.handleInOut(1.03));
		this.el.addEventListener("mouseleave", () => this.handleInOut(0));
		EventEmitter.on(EVENTS.DOC_SCROLL, () => this.handleScroll());
		Window.listenOnResize(() => this.handleResize());
		Mouse.listenOnMouseMove(() => this.handleMove());
	}

	handleScroll() {
		this.top = this.getCoordinates("top");
		this.left = this.getCoordinates("left");

		this.cursor.style.left = Mouse.clientX - this.left + "px";
		this.cursor.style.top = Mouse.clientY - this.top + "px";
	}

	handleResize() {
		this.top = this.getCoordinates("top");
		this.left = this.getCoordinates("left");

		this.cursor.style.left = Mouse.pageX - this.left + "px";
		this.cursor.style.top = Mouse.pageY - (this.top + window.scrollY) + "px";
	}

	handleMove() {
		this.top = this.getCoordinates("top");
		this.left = this.getCoordinates("left");

		this.cursor.style.left = Mouse.pageX - this.left + "px";
		this.cursor.style.top = Mouse.pageY - (this.top + window.scrollY) + "px";
	}

	handleInOut(style) {
		this.cursor.style.transform = "translate(-50%, -50%) scale(" + style + ")";
		this.cursor.style.opacity = style;
	}

	getCoordinates(type) {
		const details = this.el.getBoundingClientRect();
		return details[type];
	}

	destroy() {
		this.el.removeEventListener("mouseenter", () => this.handleInOut(1));
		this.el.removeEventListener("mouseleave", () => this.handleInOut(0));
	}
}
